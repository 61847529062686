<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="400">
      <v-card-title>{{$t("route.PageMainLanguage")}}</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <div class="text-center pt-3" v-for="lang in langs" :key="lang.value">
            <v-btn
              @click="changeLang(lang.value)"
              block
              rounded
              :outlined="value != lang.value"
              color="primary"
              dark
            >
              {{ lang.name }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    prevRoute: null,
    langs: [
      {
        name: "Bahasa Melayu",
        value: "ms",
      },
      {
        name: "中文",
        value: "zh",
      },
      {
        name: "English",
        value: "en",
      },
    ],
    value: null,
     //BOC:[breadcrumbs]
     breadcrumbs: [],
      //EOC
  }),
  created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainLanguage"),
        to:{name:'PageMainLanguage'},
        exact:true,
      })
      //EOC
    this.value = this.$_getLocale();
  },
  mounted() {
    //
  },
  methods: {
    changeLang(val) {
      var path = "/" + val + this.prevRoute.fullPath;
      console.log(path);
      // console.log(path)
      //var query = href.split("?")[1] ? `?${href.split("?")[1]}` : "";
      window.location.href = path;
    },
    name: (item) => `${item.name}`,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>